'use strict';

/* eslint no-undef: "off" */
/**
 * not found page main container
 */
define('notFoundPageMain',[], function () {
  return {
    template: '\n      <div class="in-not-found-page">\n        <i class="in-icon-notFoundPage"></i>\n        <p class="in-404-note" v-text="i18n.notFoundPage"></p>\n        <div class="in-btn">\n          <a href="/" v-text="i18n.home"></a>\n        </div>\n      </div>\n    ',
    data: function data() {
      return {
        i18n: {
          notFoundPage: 'Oops, The page you were looking for cannot be found.',
          home: 'HOME'
        }
      };
    }
  };
});
