'use strict';

/**
 * not found page
 */
require(['vue', 'notFoundPageApp'], function (Vue, notFoundPageApp) {
  /* eslint no-new: "off" */
  Vue.config.productionTip = false;
  // 实例化vue
  new Vue({
    el: '#app',
    template: '<notFoundPageApp/>',
    components: {
      notFoundPageApp: notFoundPageApp
    }
  });
});
define("Magento_Theme/modules/not-page", function(){});

